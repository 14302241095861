import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";

const CommonCardDashBoard = ({ name, img, onClick }) => {
  return (
    <>
      <Tooltip title={name} placement="top">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff", // White card background
            borderRadius: "16px", // Rounded corners
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow
            textAlign: "center",
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
            width: { xs: "120px", sm: "140px" }, // Adjust card size
            height: { xs: "150px", sm: "170px" }, // Adjust card height
            overflow: "hidden",
            padding: "16px",
            border: "2px solid orange", // Orange border
            "&:hover": {
              transform: "scale(1.05)", // Enlarge on hover
              boxShadow: "0 6px 12px rgba(255, 165, 0, 0.4)", // Orange shadow on hover
            },
          }}
          onClick={onClick}
        >
          <Box
            sx={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(135deg, #fff, #f5f5f5)", // Subtle gradient
              border: "4px solid orange", // Bold orange border
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Inner shadow
              mb: 2,
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)", // Slight zoom on hover
              },
            }}
          >
            <img
              src={img}
              alt={name}
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%", // Circle image
                objectFit: "cover", // Ensures proper image scaling
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: "#333", // Dark text color
              textAlign: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
              fontSize: { xs: "14px", sm: "16px" }, // Responsive text size
            }}
          >
            {name}
          </Typography>
        </Box>
      </Tooltip>
    </>
  );
};

export default CommonCardDashBoard;
